* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #333;
  -webkit-font-smoothing: antialiased !important;
}

.MuiExpansionPanelSummary-root {
  height: 64px;
  padding-top: 10px;
}

.MuiExpansionPanelSummary-content.Mui-expanded {
  justify-content: space-between;
  margin: 0 !important;
}

@media print {
  @page {
    size: a4;
    margin-top: 5mm;
  }

  html, body {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  body * {
    visibility: hidden;
  }
  .container-to-print {
    visibility: visible !important;
    width: 100vw !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .container-to-print * {
    visibility: visible;
  }
  .section-to-print, .section-to-print * {
    visibility: visible;
  }
  .container-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
  .no-print, .no-print * {
    display: none !important;
  }
  /*.header-to-print {
    position: fixed;
    top: 0;
  }*/
}
